.wp-block-column {

	& > * + * {
		margin-block-start: var(--vertical-margin);
	}
}

.container-inline {
	container-type: inline-size;
}

.content {
	--vertical-margin: var(--spacing-l);

	@media (--bp-medium) {
		--vertical-margin: var(--spacing-l-desktop);
	}

	margin: var(--vertical-margin) auto 0;
	width: var(--max-width-content);

	& > * + * {
		--vertical-margin: var(--spacing-xl);
		margin-block: var(--vertical-margin) 0;
	}

	& > time + * {
		--vertical-margin: var(--spacing-3xl);
	}

	& .wp-block-heading {
		--vertical-margin: var(--spacing-3xl);
	}

	& ol,
	& ul {
		--vertical-margin: var(--spacing-m);

		& li:not(:first-child) {
			margin-block-start: var(--spacing-2xs);
		}
	}

	& .wp-block-image {
		margin-block: var(--vertical-margin);
	}

	& .custom-block {
		--vertical-margin: var(--spacing-3xl);
		margin-block: var(--vertical-margin);
	}

	& > .avoid-margin:first-child,
	& > .wp-block-group[class*="is-style-"]:first-child {
		margin-top: calc(var(--vertical-margin) * -1);
	}

	& .has-h-s-font-size,
	& .has-h-xs-font-size,
	& .has-h-2-xs-font-size,
	& .has-h-m-font-size,
	& .has-h-l-font-size,
	& .has-h-xl-font-size,
	& .has-h-2-xl-font-size {
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
	}

	& .has-h-s-font-size {
		--font-family: var(--typography-h-s-family);
		--font-size: var(--typography-h-s-size);
		--font-weight: var(--typography-h-s-weight);
		--letter-spacing: var(--typography-h-s-letter-spacing);
		--line-height: var(--typography-h-s-line-height);
	}

	& .has-h-xs-font-size {
		--font-family: var(--typography-h-xs-family);
		--font-size: var(--typography-h-xs-size);
		--font-weight: var(--typography-h-xs-weight);
		--letter-spacing: var(--typography-h-xs-letter-spacing);
		--line-height: var(--typography-h-xs-line-height);
	}

	& .has-h-2-xs-font-size {
		--font-family: var(--typography-h-2xs-family);
		--font-size: var(--typography-h-2xs-size);
		--font-weight: var(--typography-h-2xs-weight);
		--letter-spacing: var(--typography-h-2xs-letter-spacing);
		--line-height: var(--typography-h-2xs-line-height);
	}

	& .has-h-m-font-size {
		--font-family: var(--typography-h-m-family);
		--font-size: var(--typography-h-m-size);
		--font-weight: var(--typography-h-m-weight);
		--letter-spacing: var(--typography-h-m-letter-spacing);
		--line-height: var(--typography-h-m-line-height);
	}

	& .has-h-l-font-size {
		--font-family: var(--typography-h-l-family);
		--font-size: var(--typography-h-l-size);
		--font-weight: var(--typography-h-l-weight);
		--letter-spacing: var(--typography-h-l-letter-spacing);
		--line-height: var(--typography-h-l-line-height);
	}

	& .has-h-xl-font-size {
		--font-family: var(--typography-h-xl-family);
		--font-size: var(--typography-h-xl-size);
		--font-weight: var(--typography-h-xl-weight);
		--letter-spacing: var(--typography-h-xl-letter-spacing);
		--line-height: var(--typography-h-xl-line-height);
	}

	& .has-h-2-xl-font-size {
		--font-family: var(--typography-h-2xl-family);
		--font-size: var(--typography-h-2xl-size);
		--font-weight: var(--typography-h-2xl-weight);
		--letter-spacing: var(--typography-h-2xl-letter-spacing);
		--line-height: var(--typography-h-2xl-line-height);
	}

	& > :is(.avoid-margin, .wp-block-group[class*="is-style-"], .wp-block-image.alignfull) + :is(.avoid-margin, .wp-block-group[class*="is-style-"], .wp-block-image.alignfull) {
		margin-top: 0;
	}

	/* Fix floaty images and keep reading component */
	&::after {
		clear: both;
		content: "";
		display: block;
	}

	& > :is(h1, h2, h3, h4, h5, h6, p, ol, ul) a {
		text-decoration: underline;
		text-decoration-thickness: 1px;
	}
}

.article-post {
	overflow: hidden;

	& > :is(h1, h2, h3, h4, h5, h6, p, ol, ul) a {
		text-decoration: inherit;
		text-decoration-thickness: 1px;
	}

	& .content {
		margin: var(--vertical-margin) auto;
	}
}

.single-page {

	& .content {
		margin-block-end: var(--vertical-margin);
	}
}
