@import "../styles/raven";


:root {
	--nav-shadow: 0 4px 50px rgb(0 0 0 / 25%);

	--color-overlay-vertical: linear-gradient(180deg, rgb(from var(--color-neutral-black) r g b / 0%) 0%, rgb(from var(--color-neutral-black) r g b / 75%) 100%);
	--color-overlay-horizontal: linear-gradient(90deg, rgb(from var(--color-neutral-black) r g b / 0%) 0%, rgb(from var(--color-neutral-black) r g b / 75%) 25%, rgb(from var(--color-neutral-black) r g b / 75%) 100%);

	--wp--preset--spacing--0: var(--spacing-0);
	--wp--preset--spacing--s: var(--spacing-s);
	--wp--preset--spacing--xs: var(--spacing-xs);
	--wp--preset--spacing--2-xs: var(--spacing-2xs);
	--wp--preset--spacing--3-xs: var(--spacing-3xs);
	--wp--preset--spacing--4-xs: var(--spacing-4xs);
	--wp--preset--spacing--l: var(--spacing-l);
	--wp--preset--spacing--m: var(--spacing-m);
	--wp--preset--spacing--xl: var(--spacing-xl);
	--wp--preset--spacing--2-xl: var(--spacing-2xl);
	--wp--preset--spacing--3-xl: var(--spacing-3xl);
	--wp--preset--spacing--4-xl: var(--spacing-4xl);
	--wp--preset--spacing--5-xl: var(--spacing-5xl);

	/* Sizing */
	--gutter: var(--spacing-l);
	--edge-gutter: var(--grid-xs-gutter);
	--header-height: 4rem;
	--sticky-height: 3.15rem;
	--max-width-wide: 68.75rem;
	--max-width-content-fixed: 46.875rem;
	--max-width-content: min(calc(100% - (var(--edge-gutter) * 2)), var(--max-width-content-fixed));
	--max-width-grid: 56.25rem;
	--footer-ad-height: 0px; /* stylelint-disable-line length-zero-no-unit */

	/* Transitions */
	--transition-speed-fast: 0.125s;
	--transition-speed-normal: 0.3s;
	--transition-speed-slow: 0.5s;
	--transition-easing-in-out: cubic-bezier(0.54, 0.28, 0.42, 0.87);

	@media (--bp-medium) {
		--gutter: var(--spacing-xl);
		--header-height: 5.625rem;
	}

	@media (--bp-large) {
		--wp--preset--spacing--0: var(--spacing-0-desktop);
		--wp--preset--spacing--s: var(--spacing-s-desktop);
		--wp--preset--spacing--xs: var(--spacing-xs-desktop);
		--wp--preset--spacing--2-xs: var(--spacing-2xs-desktop);
		--wp--preset--spacing--3-xs: var(--spacing-3xs-desktop);
		--wp--preset--spacing--4-xs: var(--spacing-4xs-desktop);
		--wp--preset--spacing--l: var(--spacing-l-desktop);
		--wp--preset--spacing--m: var(--spacing-m-desktop);
		--wp--preset--spacing--xl: var(--spacing-xl-desktop);
		--wp--preset--spacing--2-xl: var(--spacing-2xl-desktop);
		--wp--preset--spacing--3-xl: var(--spacing-3xl-desktop);
		--wp--preset--spacing--4-xl: var(--spacing-4xl-desktop);
		--wp--preset--spacing--5-xl: var(--spacing-5xl-desktop);
	}
}
