.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:active) {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
}

/* Pinit.js Save Button Z-index override */
[data-pin-log="button_pinit_floating"] {
	z-index: 10 !important;
}
