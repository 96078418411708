/* Display Breakpoints */
@custom-media --size-a ( max-width: 30em );
@custom-media --size-b ( min-width: 30.01em ) and ( max-width: 48em );
@custom-media --size-c ( min-width: 48.01em ) and ( max-width: 64em );
@custom-media --size-d ( min-width: 64.01em );

.not-size-a {

	@media (--size-a) {
		display: none !important;
	}
}

.not-size-b {

	@media (--size-b) {
		display: none !important;
	}
}

.not-size-c {

	@media (--size-c) {
		display: none !important;
	}
}

.not-size-d {

	@media (--size-d) {
		display: none !important;
	}
}
