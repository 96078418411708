:root {
	--transition-speed-fast: 0.125s;
	--transition-speed-normal: 0.3s;
	--transition-easing-in-out: cubic-bezier(0.54, 0.28, 0.42, 0.87);
}

.fade-in-top {
	--transition: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);

	opacity: 0;
	transition: opacity var(--transition);

	@media (--allows-motion) {
		transform: translateY(-50px);
		transition: opacity var(--transition), transform var(--transition);
	}

	&.visible {
		opacity: 1;

		@media (--allows-motion) {
			transform: translateY(0);
		}
	}
}

.slide-in-left-children {
	--transition: 0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94);

	overflow: hidden;

	& > * {
		opacity: 0;
		transition: opacity var(--transition);

		@media (--allows-motion) {
			transform: translate3d(-100%, 0, 0);
			transform-origin: 0 0;
			transition: opacity var(--transition), transform var(--transition);
		}
	}

	&.visible > * {
		opacity: 1;

		@media (--allows-motion) {
			transform: translate3d(0, 0, 0);
		}
	}
}
