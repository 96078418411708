.wp-block-separator {
	--background: var(--separator-color);
	--separator-height: var(--separator-thickness);

	background: var(--background);
	border: none;
	height: var(--separator-height);
	margin-inline: auto;
	max-width: 280px;
}

.is-style-dark {

	& .wp-block-separator {
		--background: var(--separator-dark-color);
		--separator-height: var(--separator-dark-thickness);
	}
}
