html {
	box-sizing: border-box;
	display: flex;
	min-height: 100dvh;
	min-height: 100vh;
	scroll-padding-top: var(--scroll-padding, 0);

	@media (--allows-motion) {
		scroll-behavior: smooth;
	}
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

body,
#storybook-root {
	--screen-width: calc(100vw - var(--scroll-bar, 0px));
	--margin-full-width: calc(-50vw + 50% + (var(--scroll-bar, 0px) / 2));

	--font-family: var(--typography-body-family);
	--font-size: var(--typography-body-size);
	--font-weight: var(--typography-body-weight);
	--letter-spacing: var(--typography-body-letter-spacing);
	--line-height: var(--typography-body-line-height);
	--outline-color: var(--color-theme-link-focus-outline-color);
	--outline-style: var(--color-theme-link-focus-outline-style);
	--outline-width: var(--color-theme-link-focus-outline-width);

	@media (--bp-large) {
		--font-family: var(--typography-body-desktop-family);
		--font-size: var(--typography-body-desktop-size);
		--font-weight: var(--typography-body-desktop-weight);
		--letter-spacing: var(--typography-body-desktop-letter-spacing);
		--line-height: var(--typography-body-desktop-line-height);
	}

	display: flex;
	flex-direction: column;
	font-family: var(--font-family);
	font-size: var(--font-size);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	width: 100%;

	&.is-scroll-locked {
		overflow: hidden;
		-webkit-overflow-scrolling: touch;
	}

	& main {
		flex-grow: 1;
	}
}

body {
	--background-color: var(--color-theme-background);
	--color: var(--color-theme-text);

	background-color: var(--background-color);
	color: var(--color);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin: 0;

	& a {
		font-family: inherit;
	}
}

h1 {
	--font-family: var(--typography-h-2xl-family);
	--font-size: var(--typography-h-2xl-size);
	--font-weight: var(--typography-h-2xl-weight);
	--letter-spacing: var(--typography-h-2xl-letter-spacing);
	--line-height: var(--typography-h-2xl-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-2xl-desktop-family);
		--font-size: var(--typography-h-2xl-desktop-size);
		--font-weight: var(--typography-h-2xl-desktop-weight);
		--letter-spacing: var(--typography-h-2xl-desktop-letter-spacing);
		--line-height: var(--typography-h-2xl-desktop-line-height);
	}
}

h2 {
	--font-family: var(--typography-h-xl-family);
	--font-size: var(--typography-h-xl-size);
	--font-weight: var(--typography-h-xl-weight);
	--letter-spacing: var(--typography-h-xl-letter-spacing);
	--line-height: var(--typography-h-xl-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-xl-desktop-family);
		--font-size: var(--typography-h-xl-desktop-size);
		--font-weight: var(--typography-h-xl-desktop-weight);
		--letter-spacing: var(--typography-h-xl-desktop-letter-spacing);
		--line-height: var(--typography-h-xl-desktop-line-height);
	}
}

h3 {
	--font-family: var(--typography-h-l-family);
	--font-size: var(--typography-h-l-size);
	--font-weight: var(--typography-h-l-weight);
	--letter-spacing: var(--typography-h-l-letter-spacing);
	--line-height: var(--typography-h-l-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-l-desktop-family);
		--font-size: var(--typography-h-l-desktop-size);
		--font-weight: var(--typography-h-l-desktop-weight);
		--letter-spacing: var(--typography-h-l-desktop-letter-spacing);
		--line-height: var(--typography-h-l-desktop-line-height);
	}
}

h4 {
	--font-family: var(--typography-h-m-family);
	--font-size: var(--typography-h-m-size);
	--font-weight: var(--typography-h-m-weight);
	--letter-spacing: var(--typography-h-m-letter-spacing);
	--line-height: var(--typography-h-m-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-m-desktop-family);
		--font-size: var(--typography-h-m-desktop-size);
		--font-weight: var(--typography-h-m-desktop-weight);
		--letter-spacing: var(--typography-h-m-desktop-letter-spacing);
		--line-height: var(--typography-h-m-desktop-line-height);
	}
}

h5 {
	--font-family: var(--typography-h-s-family);
	--font-size: var(--typography-h-s-size);
	--font-weight: var(--typography-h-s-weight);
	--letter-spacing: var(--typography-h-s-letter-spacing);
	--line-height: var(--typography-h-s-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-s-desktop-family);
		--font-size: var(--typography-h-s-desktop-size);
		--font-weight: var(--typography-h-s-desktop-weight);
		--letter-spacing: var(--typography-h-s-desktop-letter-spacing);
		--line-height: var(--typography-h-s-desktop-line-height);
	}
}

h6 {
	--font-family: var(--typography-h-xs-family);
	--font-xsize: var(--typography-h-xs-size);
	--font-weight: var(--typography-h-xs-weight);
	--xsetter-xspacing: var(--typography-h-xs-letter-spacing);
	--xsine-height: var(--typography-h-xs-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-h-xs-desktop-family);
		--font-xsize: var(--typography-h-xs-desktop-size);
		--font-weight: var(--typography-h-xs-desktop-weight);
		--xsetter-xspacing: var(--typography-h-xs-desktop-letter-spacing);
		--xsine-height: var(--typography-h-xs-desktop-line-height);
	}
}

p,
ol,
ul {
	--font-family: var(--typography-body-family);
	--font-size: var(--typography-body-size);
	--font-weight: var(--typography-body-weight);
	--letter-spacing: var(--typography-body-letter-spacing);
	--line-height: var(--typography-body-line-height);

	@media (--bp-large) {
		--font-family: var(--typography-body-desktop-family);
		--font-size: var(--typography-body-desktop-size);
		--font-weight: var(--typography-body-desktop-weight);
		--letter-spacing: var(--typography-body-desktop-letter-spacing);
		--line-height: var(--typography-body-desktop-line-height);
	}

	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	margin: 0;
}

a {
	--color: var(--color-theme-link);
	--color-active: var(--color-theme-link-active);
	--color-focus: var(--color-theme-link-focus);
	--color-hover: var(--color-theme-link-hover);
	--color-outline: var(--color-theme-link-focus-outline-color);
	--color-text-decoration: var(--color);
	--font-family: var(--typography-link-family);
	--font-size: var(--typography-link-size);
	--font-weight: var(--typography-link-weight);
	--letter-spacing: var(--typography-link-letter-spacing);
	--line-height: var(--typography-link-line-height);
	--style-outline: var(--color-theme-link-focus-outline-style);
	--text-decoration: var(--typography-link-text-decoration);
	--width-outline: var(--color-theme-link-focus-outline-width);

	color: var(--color);
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	text-decoration: var(--text-decoration);
	text-decoration-color: transparent;
	text-decoration-thickness: 1px;
	text-underline-offset: 0.1rem;
	transition: text-decoration-color var(--transition-speed-fast), text-underline-offset var(--transition-speed-fast), color var(--transition-speed-fast);

	&:focus {
		--color: var(--color-focus);
	}

	&:active {
		--color: var(--color-active);
	}

	&:hover {
		--color: var(--color-hover);
	}

	&:focus,
	&:hover {
		text-decoration-color: var(--color-text-decoration);
		text-underline-offset: 0.25rem;
	}

	@media (--bp-large) {
		--font-family: var(--typography-link-desktop-family);
		--font-size: var(--typography-link-desktop-size);
		--font-weight: var(--typography-link-desktop-weight);
		--letter-spacing: var(--typography-link-desktop-letter-spacing);
		--line-height: var(--typography-link-desktop-line-height);
		--text-decoration: var(--typography-link-desktop-text-decoration);
	}

	&:has(.card) {
		--color: unset;
		--color-active: unset;
		--color-focus: unset;
		--color-hover: unset;
		--color-outline: unset;
		--color-text-decoration: unset;
		--font-family: unset;
		--font-size: unset;
		--font-weight: unset;
		--letter-spacing: unset;
		--line-height: unset;
		--style-outline: unset;
		--text-decoration: unset;
		--width-outline: unset;
	}
}

*:focus-visible {
	--outline-focus: var(--outline-width, 1px) var(--outline-style, solid) var(--outline-color, currentcolor);

	outline: var(--outline-focus);
	outline-offset: 2px;
}

:global(.has-dark-theme) *:focus-visible {
	--outline-color: var(--color-theme-dark-link-focus-outline-color);
	--outline-style: var(--color-theme-dark-link-focus-outline-style);
	--outline-width: var(--color-theme-dark-link-focus-outline-width);
}

/* remove after SearchWidget is removed */
.grecaptcha-badge {
	visibility: hidden;
}
