.wp-block-the-arena-group-buttons {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-2xs);

	@media (--bp-medium) {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.wp-block-the-arena-group-buttons.is-content-justification-left {
	justify-content: flex-start;
}

.wp-block-the-arena-group-buttons.is-content-justification-center {
	justify-content: center;
}

.wp-block-the-arena-group-buttons.is-content-justification-right {
	justify-content: flex-end;
}
