.surfer-tv-grid {
	--wp--style--block-gap: var(--spacing-2xs);
	gap: var(--spacing-xl);
	justify-content: center;
	text-align: center;

	@media (--wp-medium) {
		text-align: left;
	}

	& .wp-block-heading {
		margin-bottom: var(--spacing-s);
	}

	& .wp-block-image {
		border: 1px solid var(--color-neutral-light-20);
		border-radius: 200px;
		height: 47px;
		margin: 0;
		padding: 7.5px 10px;
		transition: background-color var(--transition-speed-normal), border-color var(--transition-speed-normal);

		&,
		& a {
			align-items: center;
			display: flex;
			justify-content: center;
		}

		& img {
			border-radius: 0;
			height: 30px;
			object-fit: contain;
			object-position: center;
			transition: filter var(--transition-speed-normal);
			width: 88%;
		}

		&:has(a:hover) {
			background-color: var(--color-neutral-white);
			border-color: var(--color-neutral-white);

			& img {
				filter: invert(1);
			}
		}
	}
}
