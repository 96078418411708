/* to do: remove after we add a toggle to disable the page title */
.single-page:has(.is-health-kit-page-header) {

	& > .content {
		margin-top: 0;

		& .is-health-kit-page-header {
			margin-top: 0;
		}
	}

	& > .content > h1:first-child {
		display: none;
	}
}

.wp-block-group.is-category-card-grid-title-pattern {

	& .wp-block-columns {
		margin-block: 0;
	}

	& .wp-block-columns.alignwide {
		max-width: 85rem;
	}
}

.wp-block-group.is-category-card-grid-pattern {

	& > .wp-block-group.alignwide {
		display: grid;
		gap: var(--spacing-m);
		grid-template-columns: repeat(2, minmax(0, 1fr));
		max-width: 85rem;
		padding: 0;

		@media (--bp-medium) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}

		@media (--bp-large) {
			grid-template-columns: repeat(5, minmax(0, 1fr));
		}

		& .wp-block-group {
			margin: 0;

			& .wp-block-image {
				margin-bottom: var(--spacing-s);
				margin-top: 0;
				max-width: none;
			}

			& .wp-block-heading {
				margin-bottom: 0;
				margin-top: 0;
				max-width: none;
			}
		}
	}
}

.wp-block-group.is-split-video-pattern {
	padding-inline: var(--gutter);
}

.wp-block-group.is-split-video-pattern > .wp-block-group {
	display: grid;
	margin-inline: auto;
	max-width: 85rem;
	width: 100%;

	@media (--bp-medium) {
		align-items: center;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	& .wp-block-embed {
		margin: 0;
		max-width: none;
		width: 100%;
	}

	& .wp-block-group {
		max-width: none;
	}

	& > .wp-block-group {
		margin-bottom: 0;
		margin-top: 0;
		padding: var(--spacing-m);

		& > * {
			margin: 0;
			max-width: none;
		}

		& > * + * {
			margin-top: var(--spacing-xs);
		}
	}
}

.wp-block-group.has-85rem-max-width {
	--max-width-content: 85rem;
}

.wp-block-group.is-health-kit-info-columns {
	--max-width-content: 85rem;

	padding-inline: var(--gutter);

	& .wp-block-columns {
		gap: var(--spacing-xl);
		margin-inline: auto;
		max-width: var(--max-width-content);
	}

	& .wp-block-column {
		margin: 0 !important;
	}

	& .wp-block-heading + p,
	& p + p {
		margin-top: var(--spacing-xs);
	}
}
