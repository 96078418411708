html.site--thebusinessofeverything {
	--max-width-wide: 85rem;
	--wp--style--block-gap: 2rem;
	--edge-gutter: var(--spacing-s);

	& .podcast-block__tag,
	& .podcast-block__text {
		--font-size: var(--card-typography-title-desktop-size);

		font-size: var(--font-size);
	}

	& .podcast-block .wp-block-column > * + * {
		margin-top: var(--spacing-m);

		@media (--bp-medium) {
			margin-top: var(--spacing-l);
		}
	}

	& .podcast-block .wp-block-image {
		margin-left: 0;
		max-width: 17.125rem;
		width: 100%;

		@media (--bp-medium) {
			margin-left: auto;
			max-width: 100%;
		}

		& > img {
			width: 100%;
		}
	}

	& .episode__title {
		--font-family: var(--typography-subhead-family);

		font-family: var(--font-family);
		text-transform: uppercase;
	}


	@media (max-width: 599px) {

		& .podcast-block .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:only-child, :last-child) {
			margin-bottom: var(--spacing-m);
		}
	}
}
