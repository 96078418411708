.wp-block-group {
	overflow: hidden;

	&.alignfull {
		clear: both;
		margin-left: var(--margin-full-width);
		margin-right: var(--margin-full-width);
		max-width: var(--screen-width);
	}

	&.alignwide {
		margin-left: var(--margin-full-width);
		margin-right: var(--margin-full-width);
		max-width: var(--screen-width);
		padding-inline: var(--gutter);

		&.is-layout-flow > * {
			margin: 0 auto;
			max-width: var(--max-width-wide);
		}
	}

	&:not(.alignfull, .alignwide) {
		--max-width-content: min(calc(100% - (var(--grid-s-gutter) * 2)), var(--max-width-content-fixed));
	}

	&:not(.alignfull) {
		border-radius: var(--radius-containers);
	}

	&.is-layout-constrained {

		& > *:not(:first-child) {
			margin-block-start: var(--vertical-margin);
		}

		& > *:not(.alignfull) {
			margin-inline: auto;
			max-width: var(--max-width-content);

			&.alignwide {
				max-width: var(--max-width-wide);
			}
		}
	}

	&.is-layout-flex {
		gap: var(--spacing-m);
	}

	& .wp-block-column .wp-block-group .wp-block-image {
		max-width: initial;
	}

	&.is-content-justification-left {
		justify-content: flex-start;
	}

	&.is-content-justification-left.is-vertical {
		align-items: flex-start;
	}

	&.is-content-justification-center {
		justify-content: center;
	}

	&.is-content-justification-center.is-vertical {
		align-items: center;
	}

	&.is-content-justification-right {
		justify-content: flex-end;
	}

	&.is-content-justification-right.is-vertical {
		align-items: flex-end;
	}

	&.is-content-justification-space-between {
		justify-content: space-between;
	}
}

.wp-block-group + .wp-block-group {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.wp-block-group:has(+ .wp-block-group) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
