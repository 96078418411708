.button,
.wp-block-button .wp-block-button__link {
	--background-color: var(--btn-primary-background);
	--border:
		var(--btn-primary-border-width)
		var(--btn-primary-border-style)
		var(--btn-primary-border-color);
	--border-radius: var(--radius-buttons);
	--color: var(--btn-primary-color);
	--font-family: var(--typography-btn-family);
	--font-size: var(--typography-btn-size);
	--font-weight: var(--typography-btn-weight);
	--letter-spacing: var(--typography-btn-letter-spacing);
	--line-height: var(--typography-btn-line-height);

	/* hover */
	--background-color-hover: var(--btn-primary-hover-background);
	--border-color-hover: var(--btn-primary-hover-border-color);
	--color-hover: var(--btn-primary-hover-color);

	/* active */
	--background-color-active: var(--btn-primary-active-background);
	--border-color-active: var(--btn-primary-active-border-color);
	--color-active: var(--btn-primary-active-color);

	/* focus */
	--background-color-focus: var(--btn-primary-focus-background);
	--border-color-focus: var(--btn-primary-focus-border-color);
	--color-focus: var(--btn-primary-focus-color);
	--outline-focus:
		var(--btn-primary-focus-outline-width)
		var(--btn-primary-focus-outline-style)
		var(--btn-primary-focus-outline-color);

	@media (--bp-large) {
		--font-family: var(--typography-btn-desktop-family);
		--font-size: var(--typography-btn-desktop-size);
		--font-weight: var(--typography-btn-desktop-weight);
		--letter-spacing: var(--typography-btn-desktop-letter-spacing);
		--line-height: var(--typography-btn-desktop-line-height);
	}

	align-items: center;
	background-color: var(--background-color);
	border: var(--border);
	border-radius: var(--border-radius);
	color: var(--color);
	cursor: pointer;
	display: inline-flex;
	flex: none;
	font-family: var(--font-family);
	font-size: var(--font-size);
	font-weight: var(--font-weight);
	gap: var(--spacing-3xs);
	letter-spacing: var(--letter-spacing);
	line-height: var(--line-height);
	padding: 1rem;
	text-decoration: none;
	transition:
		border-color var(--transition-speed-normal),
		color var(--transition-speed-normal),
		background-color var(--transition-speed-normal);

	@media (--bp-large) {
		gap: var(--spacing-3xs-desktop);
	}

	&:hover,
	&:hover:visited {
		background-color: var(--background-color-hover);
		border-color: var(--border-color-hover);
		color: var(--color-hover);
	}

	&:focus,
	&:focus:visited {
		background-color: var(--background-color-focus);
		border-color: var(--border-color-focus);
		color: var(--color-focus);
		outline: var(--outline-focus);
		outline-offset: 4px;
	}

	&:active,
	&:active:visited {
		background-color: var(--background-color-active);
		border-color: var(--border-color-active);
		color: var(--color-active);
	}

	&[disabled],
	&.button--disabled {
		--background-color: var(--color-neutral-dark-10);
		--border-color: transparent;
		--color: var(--color-neutral-dark-20);
		cursor: not-allowed;
		pointer-events: none;

		&.button--icon-small-no-fill,
		&.button--icon-large-no-fill {
			--background-color: transparent;
		}
	}

	/* stylelint-disable selector-nested-pattern */
	& svg {
		fill: currentColor;
		height: 1rem;
		width: 1rem;
	}
	/* stylelint-enable selector-nested-pattern */
}

.wp-block-button .wp-block-button__link {
	margin-top: 0;
}

.button--secondary,
.wp-block-button.is-style-secondary .wp-block-button__link {
	--background-color: var(--btn-secondary-background);
	--border:
		var(--btn-secondary-border-width)
		var(--btn-secondary-border-style)
		var(--btn-secondary-border-color);
	--border-radius: var(--radius-buttons);
	--color: var(--btn-secondary-color);
	--font-family: var(--typography-btn-family);
	--font-size: var(--typography-btn-size);
	--font-weight: var(--typography-btn-weight);
	--letter-spacing: var(--typography-btn-letter-spacing);
	--line-height: var(--typography-btn-line-height);

	/* hover */
	--background-color-hover: var(--btn-secondary-hover-background);
	--border-color-hover: var(--btn-secondary-hover-border-color);
	--color-hover: var(--btn-secondary-hover-color);

	/* active */
	--background-color-active: var(--btn-secondary-active-background);
	--border-color-active: var(--btn-secondary-active-border-color);
	--color-active: var(--btn-secondary-active-color);

	/* focus */
	--background-color-focus: var(--btn-secondary-focus-background);
	--border-color-focus: var(--btn-secondary-focus-border-color);
	--color-focus: var(--btn-secondary-focus-color);
	--outline-focus:
		var(--btn-secondary-focus-outline-width)
		var(--btn-secondary-focus-outline-style)
		var(--btn-secondary-focus-outline-color);
}

.button--link,
.wp-block-button.is-style-link .wp-block-button__link {
	--background-color: transparent;
	--border: none;
	--color: var(--btn-link-color);
	--font-family: var(--typography-btn-link-family);
	--font-size: var(--typography-btn-link-size);
	--font-weight: var(--typography-btn-link-weight);
	--letter-spacing: var(--typography-btn-link-letter-spacing);
	--line-height: var(--typography-btn-link-line-height);
	--text-decoration-color: var(--btn-link-decoration-color);

	/* hover */
	--background-color-hover: transparent;
	--color-hover: var(--btn-link-hover-color);
	--text-decoration-hover: var(--btn-link-hover-decoration-color);

	/* active */
	--background-color-active: transparent;
	--color-active: var(--btn-link-active-color);
	--text-decoration-active: var(--btn-link-active-decoration-color);

	/* focus */
	--background-color-focus: transparent;
	--color-focus: var(--btn-link-focus-color);
	--text-decoration-focus: var(--btn-link-focus-decoration-color);

	@media (--bp-large) {
		--font-family: var(--typography-btn-link-desktop-family);
		--font-size: var(--typography-btn-link-desktop-size);
		--font-weight: var(--typography-btn-link-desktop-weight);
		--letter-spacing: var(--typography-btn-link-desktop-letter-spacing);
		--line-height: var(--typography-btn-link-desktop-line-height);
	}

	padding: 0;
	text-decoration: underline;
	text-decoration-color: var(--text-decoration-color);
	text-underline-offset: 4px;

	&:hover,
	&:hover:visited {
		text-decoration-color: var(--text-decoration-hover);
	}

	&:focus,
	&:focus:visited {
		text-decoration: none;
	}

	&:active,
	&:active:visited {
		text-decoration-color: var(--text-decoration-focus);
	}
}

.button--primary-square,
.button--primary-square-no-fill {
	height: 3rem;
	justify-content: center;
	padding: 0;
	width: 3rem;
}

.button--icon-small,
.button--icon-large,
.button--icon-small-no-fill,
.button--icon-large-no-fill {
	--background-color: var(--btn-icons-background);
	--border-color: var(--btn-icons-border-color);
	--border:
		var(--btn-icons-border-width)
		var(--btn-icons-border-style)
		var(--btn-icons-border-color);
	--color: var(--btn-icons-color);

	/* hover */
	--background-color-hover: var(--btn-icons-hover-background);
	--border-color-hover: var(--btn-icons-hover-border-color);
	--color-hover: var(--btn-icons-hover-color);

	/* active */
	--background-color-active: var(--btn-icons-active-background);
	--border-color-active: var(--btn-icons-active-border-color);
	--color-active: var(--btn-icons-active-color);

	/* focus */
	--background-color-focus: var(--btn-icons-focus-background);
	--border-color-focus: var(--btn-icons-focus-border-color);
	--color-focus: var(--btn-icons-focus-color);
	--outline-focus:
		var(--btn-icons-focus-outline-width)
		var(--btn-icons-focus-outline-style)
		var(--btn-icons-focus-outline-color);

	align-items: center;
	border-radius: 50%;
	justify-content: center;
	padding: 0;
}

.button.button--primary-square-no-fill,
.button.button--icon-small-no-fill,
.button.button--icon-large-no-fill {
	--background-color: transparent;
	--color: var(--color-theme2);

	.is-style-dark & {
		--background-color: transparent;
		--color: var(--color-theme1);
	}
}

.button--icon-small,
.button--icon-small-no-fill {
	height: var(--btn-icons-size, 2rem);
	width: var(--btn-icons-size, 2rem);
}

.button--icon-large,
.button--icon-large-no-fill {
	height: var(--btn-icons-l-size, 2.5rem);
	width: var(--btn-icons-l-size, 2.5rem);
}

.button--primary,
.button--secondary,
.button--tertiary {
	justify-content: center;
	width: 100%;

	@media (--bp-medium) {
		width: auto;
	}
}

@container style(--is-dark-mode: 1) {

	.button,
	& .button {
		--background-color: var(--btn-primary-dark-background);
		--border-color: var(--btn-primary-dark-border-color);
		--color: var(--btn-primary-dark-color);

		/* hover */
		--background-color-hover: var(--btn-primary-dark-hover-background);
		--border-color-hover: var(--btn-primary-dark-hover-border-color);
		--color-hover: var(--btn-primary-dark-hover-color);

		/* active */
		--background-color-active: var(--btn-primary-dark-active-background);
		--border-color-active: var(--btn-primary-dark-active-border-color);
		--color-active: var(--btn-primary-dark-active-color);

		/* focus */
		--background-color-focus: var(--btn-primary-dark-focus-background);
		--border-color-focus: var(--btn-primary-dark-focus-border-color);
		--color-focus: var(--btn-primary-dark-focus-color);
		--outline-focus:
			var(--btn-primary-dark-focus-outline-width)
			var(--btn-primary-dark-focus-outline-style)
			var(--btn-primary-dark-focus-outline-color);

		color: var(--color);

		&[disabled],
		&.button--disabled {
			--background-color: transparent;
			--border-color: transparent;
			--color: var(--color-neutral-light-20);
			pointer-events: none;

			&.button--icon-small-no-fill,
			&.button--icon-large-no-fill {
				--background-color: transparent;
			}
		}
	}

	.button--secondary,
	& .button--secondary {
		--background-color: var(--btn-secondary-dark-background);
		--border-color: var(--btn-secondary-dark-border-color);
		--color: var(--btn-secondary-dark-color);
		--border:
			var(--btn-secondary-dark-border-width)
			var(--btn-secondary-dark-border-style)
			var(--border-color);

		/* hover */
		--background-color-hover: var(--btn-secondary-dark-hover-background);
		--border-color-hover: var(--btn-secondary-dark-hover-border-color);
		--color-hover: var(--btn-secondary-dark-hover-color);

		/* active */
		--background-color-active: var(--btn-secondary-dark-active-background);
		--border-color-active: var(--btn-secondary-dark-active-border-color);
		--color-active: var(--btn-secondary-dark-active-color);

		/* focus */
		--background-color-focus: var(--btn-secondary-dark-focus-background);
		--border-color-focus: var(--btn-secondary-dark-focus-border-color);
		--color-focus: var(--btn-secondary-dark-focus-color);
		--outline-focus:
			var(--btn-secondary-dark-focus-outline-width)
			var(--btn-secondary-dark-focus-outline-style)
			var(--btn-secondary-dark-focus-outline-color);
	}

	.button--icon-small,
	& .button--icon-small,
	.button--icon-large,
	& .button--icon-large {
		--background-color: var(--btn-icons-dark-background);
		--border-color: var(--btn-icons-dark-border-color);
		--color: var(--btn-icons-dark-color);

		/* hover */
		--background-color-hover: var(--btn-icons-dark-hover-background);
		--border-color-hover: var(--btn-icons-dark-hover-border-color);
		--color-hover: var(--btn-icons-dark-hover-color);

		/* active */
		--background-color-active: var(--btn-icons-dark-active-background);
		--border-color-active: var(--btn-icons-dark-active-border-color);
		--color-active: var(--btn-icons-dark-active-color);

		/* focus */
		--background-color-focus: var(--btn-icons-dark-focus-background);
		--border-color-focus: var(--btn-icons-dark-focus-border-color);
		--color-focus: var(--btn-icons-dark-focus-color);
		--outline-focus:
			var(--btn-icons-dark-focus-outline-width)
			var(--btn-icons-dark-focus-outline-style)
			var(--btn-icons-dark-focus-outline-color);
	}

	.button--link,
	& .button--link,
	.is-style-dark .wp-block-button.is-style-link .wp-block-button__link {
		--background-color: transparent;
		--color: var(--btn-link-dark-color);
		--text-decoration-color: var(--btn-link-dark-decoration-color);

		/* hover */
		--background-color-hover: transparent;
		--color-hover: var(--btn-link-dark-hover-color);
		--text-decoration-hover: var(--btn-link-dark-hover-decoration-color);

		/* active */
		--background-color-active: transparent;
		--color-active: var(--btn-link-dark-active-color);
		--text-decoration-active: var(--btn-link-dark-active-decoration-color);

		/* focus */
		--background-color-focus: transparent;
		--color-focus: var(--btn-link-dark-focus-color);
		--text-decoration-focus: var(--btn-link-dark-focus-decoration-color);
	}
}

/* https://gist.github.com/MoOx/9137295 */
.button-reset {
	background: transparent;
	border: none;
	color: inherit;
	font: inherit;
	line-height: normal;
	margin: 0;
	overflow: visible;
	padding: 0;
	width: auto;
}
