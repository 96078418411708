/* to do: remove after we add a toggle to disable the page title */
.single-page:has(.is-change-makers-hero) {

	& > .content {
		margin-top: 0;

		& .is-change-makers-hero,
		& .is-change-makers-hero .wp-block-cover {
			margin-top: 0;
		}
	}

	& > .content > h1:first-child {
		display: none;
	}
}

.is-change-makers-hero {
	--color: var(--color-neutral-white);

	& .wp-block-cover {
		padding: var(--spacing-xl);
	}

	& .wp-block-cover__inner-container {
		margin-inline: auto;
	}

	& :is(h1, h2, p) {
		color: var(--color) !important;
	}

	& .wp-block-cover__background {
		background-color: transparent !important;
		background-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 100%);
		opacity: 0.75;
	}
}
