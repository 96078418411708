.wp-block-quote,
.wp-block-pullquote {
	--border-color: var(--separator-color);
	--border-size: var(--separator-thickness);

	border-bottom: var(--border-size) solid var(--border-color);
	border-top: var(--border-size) solid var(--border-color);
	margin-left: 0;
	margin-right: 0;
	padding: var(--spacing-l) 0;

	@media (--bp-large) {
		padding: var(--spacing-l-desktop) 0;
	}

	& blockquote {
		margin: 0;
	}

	& p {
		--font-family: var(--typography-h-2xl-family);
		--font-size: var(--typography-h-2xl-size);
		--font-weight: var(--typography-h-2xl-weight);
		--letter-spacing: var(--typography-h-2xl-letter-spacing);
		--line-height: var(--typography-h-2xl-line-height);

		font-family: var(--font-family);
		font-size: var(--font-size);
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		margin-bottom: var(--spacing-xl);

		&:last-child,
		&:only-child {
			margin-bottom: 0;
		}

		@media (--bp-large) {
			--font-family: var(--typography-h-2xl-desktop-family);
			--font-size: var(--typography-h-2xl-desktop-size);
			--font-weight: var(--typography-h-2xl-desktop-weight);
			--letter-spacing: var(--typography-h-2xl-desktop-letter-spacing);
			--line-height: var(--typography-h-2xl-desktop-line-height);
		}
	}

	&.has-large-font-size p {
		--font-family: var(--typography-h-2xl-family);
		--font-size: var(--typography-h-2xl-size);
		--font-weight: var(--typography-h-2xl-weight);
		--letter-spacing: var(--typography-h-2xl-letter-spacing);
		--line-height: var(--typography-h-2xl-line-height);

		@media (--bp-large) {
			--font-family: var(--typography-h-2xl-desktop-family);
			--font-size: var(--typography-h-2xl-desktop-size);
			--font-weight: var(--typography-h-2xl-desktop-weight);
			--letter-spacing: var(--typography-h-2xl-desktop-letter-spacing);
			--line-height: var(--typography-h-2xl-desktop-line-height);
		}
	}

	&.has-medium-font-size p {
		--font-family: var(--typography-h-xl-family);
		--font-size: var(--typography-h-xl-size);
		--font-weight: var(--typography-h-xl-weight);
		--letter-spacing: var(--typography-h-xl-letter-spacing);
		--line-height: var(--typography-h-xl-line-height);

		@media (--bp-large) {
			--font-family: var(--typography-h-xl-desktop-family);
			--font-size: var(--typography-h-xl-desktop-size);
			--font-weight: var(--typography-h-xl-desktop-weight);
			--letter-spacing: var(--typography-h-xl-desktop-letter-spacing);
			--line-height: var(--typography-h-xl-desktop-line-height);
		}
	}

	&.has-small-font-size p {
		--font-family: var(--typography-h-l-family);
		--font-size: var(--typography-h-l-size);
		--font-weight: var(--typography-h-l-weight);
		--letter-spacing: var(--typography-h-l-letter-spacing);
		--line-height: var(--typography-h-l-line-height);

		@media (--bp-large) {
			--font-family: var(--typography-h-l-desktop-family);
			--font-size: var(--typography-h-l-desktop-size);
			--font-weight: var(--typography-h-l-desktop-weight);
			--letter-spacing: var(--typography-h-l-desktop-letter-spacing);
			--line-height: var(--typography-h-l-desktop-line-height);
		}
	}

	& cite {
		--font-family: var(--typography-caption-family);
		--font-size: var(--typography-caption-size);
		--font-weight: var(--typography-caption-weight);
		--letter-spacing: var(--typography-caption-letter-spacing);
		--line-height: var(--typography-caption-line-height);

		display: block;
		font-family: var(--font-family);
		font-size: var(--font-size);
		font-style: normal;
		font-weight: var(--font-weight);
		letter-spacing: var(--letter-spacing);
		line-height: var(--line-height);
		margin-top: var(--spacing-s);

		@media (--bp-large) {
			--font-family: var(--typography-caption-desktop-family);
			--font-size: var(--typography-caption-desktop-size);
			--font-weight: var(--typography-caption-desktop-weight);
			--letter-spacing: var(--typography-caption-desktop-letter-spacing);
			--line-height: var(--typography-caption-desktop-line-height);

			margin-top: var(--spacing-s-desktop);
		}
	}
}
