.is-style-dark .wp-block-table table {
	background: var(--color-theme-dark-background);
}

.is-style-dark .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
	background: var(--color-neutral-light-5);
}

.is-style-dark .wp-block-table.is-style-stripes {
	border-bottom-color: var(--color-neutral-light-40);
}
