.carousel {
	--slide-spacing: var(--spacing-2xs);
	--slide-size: 80%;

	position: relative;

	@media (--bp-medium) {
		--slide-spacing: var(--spacing-s-desktop);
		--slide-size: calc(100% / 3);
	}

	@media (--bp-large) {
		--slide-spacing: var(--spacing-s-desktop);
		--slide-size: calc(100% / 4);
	}
}

.carousel-category {
	padding-block: var(--vertical-spacing);

	& .carousel {
		--slide-spacing: var(--spacing-2xs);

		/* displays 2 slides and a part of the 3rd slide */
		--slide-size: calc(100% / 2.8);

		@media (--bp-medium) {

			/* displays 6 slides and apart of the 7th slide */
			--slide-size: calc(100% / 6.5);
		}
	}
}

.embla__viewport {
	overflow: hidden;
}

.carousel__container {
	backface-visibility: hidden;
	display: flex;
	margin-left: calc(var(--slide-spacing) * -1);
	touch-action: pan-y pinch-zoom;
}

.carousel__slide {
	flex: 0 0 var(--slide-size);
	min-width: 0;
	padding-left: var(--slide-spacing);
}

.carousel__controls {
	align-items: center;
	bottom: var(--spacing-s);
	display: flex;
	gap: var(--spacing-2xs);
	justify-content: center;
	position: absolute;
	width: 100%;
	z-index: 1;

	& ul {
		align-items: center;
		display: flex;
		gap: var(--spacing-2xs);
		justify-content: center;
		list-style: none;
		padding: 0;
	}

	& li {
		flex: 10px 0 0;
		margin-top: 0 !important;
	}
}

.carousel__dot {
	background-color: transparent;
	border: 1px solid var(--color-neutral-white);
	border-radius: 50%;
	cursor: pointer;
	display: block;
	height: 10px;
	padding: 0;
	width: 10px;
}

.carousel__dot--active {
	background-color: var(--color-neutral-white);
}
