.is-style-dark,
.is-style-pairing-1,
.is-style-pairing-2,
.is-style-pairing-3,
.is-style-pairing-4 {
	background: var(--background);
	color: var(--color);

	& .wp-block-button .wp-block-button__link {
		background-color: var(--btn-primary-background);
		color: var(--btn-primary-color);
	}
}

@property --is-dark-mode {
	inherits: true;
	initial-value: 0;
	syntax: "<number>";
}

.is-style-pairing-1 {
	--is-dark-mode: var(--color-pairing-1-is-dark-theme, 0);
}

.is-style-pairing-2 {
	--is-dark-mode: var(--color-pairing-2-is-dark-theme, 0);
}

.is-style-pairing-3 {
	--is-dark-mode: var(--color-pairing-3-is-dark-theme, 0);
}

.is-style-pairing-4 {
	--is-dark-mode: var(--color-pairing-4-is-dark-theme, 0);
}

.is-style-dark {
	--is-dark-mode: 1;
}
