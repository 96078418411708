.wp-block-image {
	max-width: fit-content;
}

.wp-block-image:not([class*="align-"]) {
	margin-inline: auto;
}

.wp-block-image.alignwide {
	display: flex;
	margin-inline: auto;
	max-width: var(--max-width-wide);
	transform: translateX(0);
	width: 100%;

	& .wp-element-caption {
		margin-inline: auto;
		max-width: var(--max-width-wide);
	}
}

.wp-block-image.alignfull {
	clear: both;
	display: flex;
	margin-inline: var(--margin-full-width);
	max-width: var(--screen-width);
	transform: translateX(0);

	& .wp-element-caption {
		margin-inline: auto;
		max-width: var(--max-width-content);
	}
}
